/* Pomoc.css */

/* Общий контейнер чата */
.chat-container {
    display: flex;
    flex-direction: row;
    height: 100vh; 
    font-family: 'Inter', sans-serif;
    background-color: #fff; /* Светлый фон */
    overflow: hidden;
  }
  
  /* Левая панель: список чатов */
  .chat-list {
    width: 30%;
    max-width: 350px;
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  /* Хедер слева */
  .chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #ffffff;
    border-bottom: 1px solid #eee;
  }
  
  /* Заголовок «Pomoc» */
  .chat-header h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  /* Списки чатов */
  .chat-list .ant-list {
    border: none;
    flex: 1;
  }
  .chat-list .ant-list-item {
    transition: background-color 0.2s ease;
    cursor: pointer;
  }
  .chat-list .ant-list-item:hover {
    background-color: #f0f0f0;
  }
  
  /* Правая часть: окно чата */
  .chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  
  /* Заголовок в правом модальном окне: title={renderChatHeader()} */
  .ant-modal-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Контейнер для сообщений */
  .messages {
    flex: 1;
    overflow-y: auto;
    position: relative;
    background-color: #fff;
  }
  
  /* Каждый «пузырёк» */
  .message-item {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.25rem;
    position: relative;
  }
  
  /* Мини-аватар в сообщении */
  .message-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.75rem;
  }
  
  /* Контейнер с текстом, временем и именем */
  .message-bubble {
    max-width: 75%;
    border-radius: 18px;
    padding: 1rem 1.25rem;
    position: relative;
    font-size: 0.95rem;
    line-height: 1.4;
    word-wrap: break-word;
    background-color: #f2f3f5;
    color: #333;
  }
  
  /* Имя и время отправителя над сообщением */
  .message-meta {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    font-size: 0.8rem;
    color: #999;
  }
  .message-meta .message-name {
    margin-right: 0.5rem;
    font-weight: 600;
    color: #333;
  }
  .message-meta .message-time {
    color: #aaa;
    font-size: 0.8rem;
  }
  
  /* Пузырёк своих сообщений (справа) */
  .you {
    flex-direction: row-reverse;
  }
  .you .message-avatar {
    margin-right: 0;
    margin-left: 0.75rem;
  }
  .you .message-bubble {
    background-color: #4169e1; /* Синий фон (из скриншота примерно) */
    color: #fff;
  }
  .you .message-meta .message-name {
    color: #fff;
  }
  .you .message-meta .message-time {
    color: #f0f0f0;
  }
  
  /* Серый «typing…» (пример, если понадобится) */
  .typing-indicator {
    font-size: 0.85rem;
    color: #aaa;
    font-style: italic;
  }
  
  /* Панель ввода сообщения и иконки */
  .message-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
    background-color: #fff;
  }
  
  .send-button {
    align-items: center;

    padding: 0.55rem;
    border-top: 1px solid #eee;
    border: none;
    border-radius: 15px;
    background-color: #4169e1;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
  }
  
  .send-button:hover {
    background-color: #3251aa;
  }
  
  .send-button:focus,
  .send-button:active {
    outline: none; 
    box-shadow: none;
  }
  
  
  /* Иконки прикрепить файл, смайлики и т.д. */
  .toolbox-icons {
    display: flex;
    align-items: center;
    border-radius: 36px;
  }
  
  /* Инпут сообщения */
  .message-input .ant-input {
    border: none;
    background-color: #f2f3f5;
    border-radius: 18px;
    padding: 0.5rem 1rem;
  }
  
  /* file-preview / image-preview */
  .image-preview,
  .file-preview {
    position: relative;
    margin-bottom: 1rem;
  }
  .image-preview img {
    max-width: 100%;
    border-radius: 6px;
  }
  .image-preview .anticon-close-circle,
  .file-preview .anticon-close-circle {
    cursor: pointer;
    font-size: 20px;
    color: #ff4d4f;
    position: absolute;
    top: 5px;
    right: 5px;
  }
  
  /* Адаптив для мобильных */
  @media (max-width: 768px) {
    .chat-container {
      flex-direction: column;
      height: auto;
    }
    .chat-list {
      width: 100%;
      max-width: 100%;
      border-right: none;
      border-bottom: 1px solid #eee;
      height: 40vh;
      overflow-y: auto;
    }
    .chat-window {
      width: 100%;
      height: calc(100vh - 40vh);
    }
    .messages {
      height: 100%;
      overflow-y: auto;
    }
  }
  